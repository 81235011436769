























































































import {Component, Vue} from 'vue-property-decorator';
import VueAsyncComputed from 'vue-async-computed';
import AsyncComputedProp from 'vue-async-computed-decorator';
import {ArkStatsBackendClient} from '@/logic/ark-stats-backend/ArkStatsBackendClient';
import DisplayName from '@/components/DisplayName.vue';
import PlayerName from '@/components/PlayerName.vue';
import DinoName from '@/components/DinoName.vue';
import ServerName from '@/components/ServerName.vue';
import TribeName from '@/components/TribeName.vue';

Vue.use(VueAsyncComputed);

@Component({
    components: {DisplayName, DinoName, PlayerName, ServerName, TribeName}
})
export default class BreedingStats extends Vue {
    private dateFormatOptions = {year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: '2-digit'} as const;

    public dinoImg(type: string): string {
        return `/images/dinos/${type}.png`;
    }

    public readableDinoType(type: string): string {
        return type;
        /*return type.replace('Character_BP_', '')
            .replace(/_C$/, '');*/
    }

    @AsyncComputedProp()
    public async dinos(): Promise<any> {
        const result = await ArkStatsBackendClient.getDinosAggregate({
            groupBy: ['type'],
            fields: ['max(stats.base.level) as max_level',
                'max(stats.base.health) as max_health',
                'max(stats.base.weight) as max_weight',
                'max(stats.base.crafting) as max_crafting',
                'max(stats.base.food) as max_food',
                'max(stats.base.stamina) as max_stamina',
                'max(stats.base.damage) as max_damage',
                'max(stats.base.speed) as max_speed',
                'max(stats.base.oxygen) as max_oxygen',
            ],
            order: ['_id'],
            filter: 'died==null;stats.base.level=le=900;neutered!=true'
        });
        return result;
    }

    public localDate(timestamp: string | Date): string {
        return new Intl.DateTimeFormat('en-US', this.dateFormatOptions).format(new Date(timestamp));
    }

}

